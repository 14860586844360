import React from "react";

const useCategorySelect = (eventCategories, selectCategoryTab) => {
  const [
    competitionCategoryTypeMenuAvailable,
    setCompetitionCategoryTypeMenuAvailable,
  ] = React.useState(null);
  const [teamCategoriesTypeAvailable, setTeamCategoriesTypeAvailable] =
    React.useState(null);
  React.useEffect(() => {
    const competitionCategoryTypeMenu = [];
    const teamCategoriesType = [];
    const barebowAvailable =
      Boolean(eventCategories?.barebowCategory?.individual?.length) ||
      Boolean(eventCategories?.barebowCategory?.team?.length) ||
      Boolean(eventCategories?.barebowCategory?.mix?.length);
    const compoundAvailable =
      Boolean(eventCategories?.compoundCategory?.individual?.length) ||
      Boolean(eventCategories?.compoundCategory?.team?.length) ||
      Boolean(eventCategories?.compoundCategory?.mix?.length);
    const nationalAvailable =
      Boolean(eventCategories?.nationalCategory?.individual?.length) ||
      Boolean(eventCategories?.nationalCategory?.team?.length) ||
      Boolean(eventCategories?.nationalCategory?.mix?.length);
    const recurveAvailable =
      Boolean(eventCategories?.recurveCategory?.individual?.length) ||
      Boolean(eventCategories?.recurveCategory?.team?.length) ||
      Boolean(eventCategories?.recurveCategory?.mix?.length);
    const traditionalBowAvailable =
      Boolean(eventCategories?.traditionalBowCategory?.individual?.length) ||
      Boolean(eventCategories?.traditionalBowCategory?.team?.length) ||
      Boolean(eventCategories?.traditionalBowCategory?.mix?.length);
    const HorsebowAvailable =
      Boolean(eventCategories?.horseBowCategory?.individual?.length) ||
      Boolean(eventCategories?.horseBowCategory?.team?.length) ||
      Boolean(eventCategories?.horseBowCategory?.mix?.length);  
    const pelatihanAvailable =
      Boolean(eventCategories?.pelatihanCategory?.individual?.length) ||
      Boolean(eventCategories?.pelatihanCategory?.team?.length) ||
      Boolean(eventCategories?.pelatihanCategory?.mix?.length);
    const recurveNationalAvailable =
      Boolean(eventCategories?.recurveNationalCategory?.individual?.length) ||
      Boolean(eventCategories?.recurveNationalCategory?.team?.length) ||
      Boolean(eventCategories?.recurveNationalCategory?.mix?.length);
    if (recurveAvailable) {
      competitionCategoryTypeMenu.push({
        value: "recurveCategory",
        label: "Recurve",
      });
      if (selectCategoryTab === "recurveCategory") {
        teamCategoriesType.splice(0, teamCategoriesType?.length);
        if (eventCategories?.recurveCategory?.individual?.length) {
          teamCategoriesType.push({ value: "individual", label: "Individu" });
        }
        if (eventCategories?.recurveCategory?.team?.length) {
          teamCategoriesType.push({ value: "team", label: "Beregu" });
        }
        if (eventCategories?.recurveCategory?.mix?.length) {
          teamCategoriesType.push({ value: "mix", label: "Campuran" });
        }
      }
    }
    if (compoundAvailable) {
      competitionCategoryTypeMenu.push({
        value: "compoundCategory",
        label: "Compound",
      });
      if (selectCategoryTab === "compoundCategory") {
        teamCategoriesType.splice(0, teamCategoriesType?.length);
        if (eventCategories?.compoundCategory?.individual?.length) {
          teamCategoriesType.push({ value: "individual", label: "Individu" });
        }
        if (eventCategories?.compoundCategory?.team?.length) {
          teamCategoriesType.push({ value: "team", label: "Beregu" });
        }
        if (eventCategories?.compoundCategory?.mix?.length) {
          teamCategoriesType.push({ value: "mix", label: "Campuran" });
        }
      }
    }
    if (nationalAvailable) {
      competitionCategoryTypeMenu.push({
        value: "nationalCategory",
        label: "National",
      });
      if (selectCategoryTab === "nationalCategory") {
        teamCategoriesType.splice(0, teamCategoriesType?.length);
        if (eventCategories?.nationalCategory?.individual?.length) {
          teamCategoriesType.push({ value: "individual", label: "Individu" });
        }
        if (eventCategories?.nationalCategory?.team?.length) {
          teamCategoriesType.push({ value: "team", label: "Beregu" });
        }
        if (eventCategories?.nationalCategory?.mix?.length) {
          teamCategoriesType.push({ value: "mix", label: "Campuran" });
        }
      }
    }
    if (barebowAvailable) {
      competitionCategoryTypeMenu.push({
        value: "barebowCategory",
        label: "Barebow",
      });
      if (selectCategoryTab === "barebowCategory") {
        teamCategoriesType.splice(0, teamCategoriesType?.length);
        if (eventCategories?.barebowCategory?.individual?.length) {
          teamCategoriesType.push({ value: "individual", label: "Individu" });
        }
        if (eventCategories?.barebowCategory?.team?.length) {
          teamCategoriesType.push({ value: "team", label: "Beregu" });
        }
        if (eventCategories?.barebowCategory?.mix?.length) {
          teamCategoriesType.push({ value: "mix", label: "Campuran" });
        }
      }
    }
    if (traditionalBowAvailable) {
      competitionCategoryTypeMenu.push({
        value: "traditionalBowCategory",
        label: "Traditional Bow",
      });
      if (selectCategoryTab === "traditionalBowCategory") {
        teamCategoriesType.splice(0, teamCategoriesType?.length);
        if (eventCategories?.traditionalBowCategory?.individual?.length) {
          teamCategoriesType.push({ value: "individual", label: "Individu" });
        }
        if (eventCategories?.traditionalBowCategory?.team?.length) {
          teamCategoriesType.push({ value: "team", label: "Beregu" });
        }
        if (eventCategories?.traditionalBowCategory?.mix?.length) {
          teamCategoriesType.push({ value: "mix", label: "Campuran" });
        }
      }
    }
    if (pelatihanAvailable) {
      competitionCategoryTypeMenu.push({
        value: "pelatihanCategory",
        label: "Pelatihan",
      });
      if (selectCategoryTab === "pelatihanCategory") {
        teamCategoriesType.splice(0, teamCategoriesType?.length);
        if (eventCategories?.pelatihanCategory?.individual?.length) {
          teamCategoriesType.push({ value: "individual", label: "Individu" });
        }
        if (eventCategories?.pelatihanCategory?.team?.length) {
          teamCategoriesType.push({ value: "team", label: "Beregu" });
        }
        if (eventCategories?.pelatihanCategory?.mix?.length) {
          teamCategoriesType.push({ value: "mix", label: "Campuran" });
        }
      }
    }
    if (HorsebowAvailable) {
      competitionCategoryTypeMenu.push({
        value: "horseBowCategory",
        label: "Horsebow",
      });
      if (selectCategoryTab === "horseBowCategory") {
        teamCategoriesType.splice(0, teamCategoriesType?.length);
        if (eventCategories?.horseBowCategory?.individual?.length) {
          teamCategoriesType.push({ value: "individual", label: "Individu" });
        }
        if (eventCategories?.horseBowCategory?.team?.length) {
          teamCategoriesType.push({ value: "team", label: "Beregu" });
        }
        if (eventCategories?.horseBowCategory?.mix?.length) {
          teamCategoriesType.push({ value: "mix", label: "Campuran" });
        }
      }
    }
    if (recurveNationalAvailable) {
      competitionCategoryTypeMenu.push({
        value: "recurveNationalCategory",
        label: "Recurve/Nasional",
      });
      if (selectCategoryTab === "recurveNationalCategory") {
        teamCategoriesType.splice(0, teamCategoriesType?.length);
        if (eventCategories?.recurveNationalCategory?.individual?.length) {
          teamCategoriesType.push({ value: "individual", label: "Individu" });
        }
        if (eventCategories?.recurveNationalCategory?.team?.length) {
          teamCategoriesType.push({ value: "team", label: "Beregu" });
        }
        if (eventCategories?.recurveNationalCategory?.mix?.length) {
          teamCategoriesType.push({ value: "mix", label: "Campuran" });
        }
      }
    }
    setCompetitionCategoryTypeMenuAvailable(competitionCategoryTypeMenu);
    setTeamCategoriesTypeAvailable(teamCategoriesType);
  }, [eventCategories, selectCategoryTab]);

  return [competitionCategoryTypeMenuAvailable, teamCategoriesTypeAvailable];
};

export { useCategorySelect };
