import * as React from "react";

export default function IconTabProfile() {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="14" cy="14" r="14" fill="#F6F6F6" />
      <path
        d="M19.3333 20V18.6667C19.3333 17.9594 19.0523 17.2811 18.5522 16.781C18.0521 16.281 17.3739 16 16.6666 16H11.3333C10.626 16 9.94777 16.281 9.44767 16.781C8.94758 17.2811 8.66663 17.9594 8.66663 18.6667V20"
        stroke="#0D47A1"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 13.3333C15.4728 13.3333 16.6667 12.1394 16.6667 10.6667C16.6667 9.19391 15.4728 8 14 8C12.5273 8 11.3334 9.19391 11.3334 10.6667C11.3334 12.1394 12.5273 13.3333 14 13.3333Z"
        stroke="#0D47A1"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
