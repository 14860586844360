import React from "react";
import { Col, Row } from "reactstrap";
// import Accordian from "./accordian";
// import CardTicket from "./cardTicket";

export const FulldayFormStep3 = () => {
  return (
    <Row>
      <Col lg={6}>
        {/* <Accordian
            question1="Virtual Account"
            answer1="BANK CENTRAL ASIA"
            question2="E-Payment"
          /> */}
      </Col>
      {/* <Col lg={4}>
          <CardTicket/>
        </Col> */}
    </Row>
  );
};
