import React from "react";

function IconMedalBronze({ size = 24 }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.1133 0.927766C14.8771 1.13777 13.0396 4.78433 13.0396 4.78433L11.6724 10.299L16.9924 9.8112C16.9924 9.8112 21.4177 1.58183 21.5074 1.39152C21.6671 1.04589 21.7524 0.903704 21.1421 0.903704C20.5317 0.901517 15.3342 0.730891 15.1133 0.927766Z"
        fill="#176CC7"
      />
      <path
        d="M17.8677 9.46937C17.6031 9.32718 9.80681 9.20468 9.62306 9.46937C9.45681 9.70999 9.55088 10.9722 9.61431 11.1231C9.72806 11.3856 12.2328 12.4028 12.2328 12.4028V13.035C12.2328 13.035 12.4034 13.105 13.8646 13.105C15.3259 13.105 15.6168 13.0306 15.6168 13.0306L15.6278 12.4225C15.6278 12.4225 17.8021 11.3025 17.9159 11.1472C18.0165 11.0181 18.1324 9.61156 17.8677 9.46937ZM15.6059 11.4687C15.6059 11.4687 15.6365 11.1669 15.4856 11.0925C15.3346 11.0159 12.7249 11.0291 12.5193 11.04C12.3137 11.0509 12.3137 11.4184 12.3137 11.4184L10.6796 10.5544L10.6512 10.2656L16.7631 10.2984L16.7762 10.6878L15.6059 11.4687Z"
        fill="#F4A14E"
      />
      <path
        d="M12.9151 11.3398C12.7095 11.3398 12.5542 11.5717 12.5542 11.9392C12.5542 12.2848 12.6723 12.5692 12.9326 12.5539C13.1798 12.5408 13.2979 12.228 13.2804 11.9348C13.2586 11.5914 13.1754 11.3398 12.9151 11.3398Z"
        fill="#FDFFFF"
      />
      <path
        d="M6.41162 20.1448C6.41162 25.3861 11.1607 27.567 14.3129 27.473C17.9857 27.3636 21.9363 24.5767 21.6104 19.5761C21.3041 14.8839 17.3032 12.6439 13.8513 12.6701C9.83943 12.6986 6.41162 15.5664 6.41162 20.1448Z"
        fill="#CD7F32"
      />
      <path
        d="M14.1136 26.5324C14.0589 26.5324 14.002 26.5324 13.9473 26.5302C12.3067 26.4755 10.6398 25.8564 9.45422 24.708C8.18547 23.4786 7.40235 21.9233 7.39797 20.1361C7.38703 15.4418 11.4098 13.7158 13.9977 13.7158H14.0195C17.5261 13.7311 20.5558 16.3518 20.6345 20.0464C20.6695 21.6914 19.9958 23.4327 18.6745 24.7321C17.443 25.9418 15.6252 26.5324 14.1136 26.5324Z"
        fill="#F4A14E"
      />
      <path
        d="M5.58029 0.813597C5.44248 0.940472 10.7122 10.3248 10.7122 10.3248C10.7122 10.3248 11.5959 10.4386 13.569 10.4561C15.5422 10.4736 16.52 10.3423 16.52 10.3423C16.52 10.3423 12.4228 0.907659 12.1778 0.813597C12.0575 0.765472 10.4344 0.747972 8.84842 0.730472C7.26029 0.710784 5.71154 0.691097 5.58029 0.813597Z"
        fill="#2E9DF4"
      />
      <path
        d="M11.4387 14.7328C11.1193 14.4593 9.49398 15.284 8.35211 17.1718C7.58648 18.4384 7.52086 20.0593 8.00867 20.0856C8.49648 20.1118 8.48992 18.5084 9.55086 17.1062C10.8327 15.4196 11.9002 15.1287 11.4387 14.7328Z"
        fill="#FEFFFA"
      />
      <path
        d="M14.0809 14.6348C13.6543 14.6348 12.9893 17.4545 12.4665 17.8023C11.9437 18.1501 9.23556 17.8176 9.20493 18.1982C9.17431 18.5788 11.2152 20.1626 11.4537 20.7466C11.6921 21.3307 10.4234 23.9448 10.7734 24.1985C11.1212 24.4523 13.3065 22.6301 14.0349 22.6301C14.794 22.6301 16.7496 24.5748 17.0756 24.3079C17.419 24.0279 16.3165 21.4729 16.4893 20.8866C16.6643 20.3004 19.0377 19.0491 18.9437 18.4804C18.8496 17.9095 16.0934 18.3382 15.5859 17.8635C15.0784 17.3888 14.5402 14.6348 14.0809 14.6348Z"
        fill="#CD7F32"
      />
    </svg>
  );
}

export default IconMedalBronze;
