import * as React from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

import ProfileMenu from "./ProfileMenu";

import logoDark from "assets/images/myachery/logo_myarhery.svg";
import logoLight from "assets/images/myachery/logo_myarhery.svg";
import logoLightSvg from "assets/images/myachery/logo_myarhery.svg";
import logo from "assets/images/myachery/logo_myarhery.svg";

const Header = () => {
  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/home" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logo} alt="" height="35" />
                </span>
                <span className="logo-lg">
                  <img src={logoDark} alt="" height="35" />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logoLightSvg} alt="" height="35" />
                </span>
                <span className="logo-lg">
                  <img src={logoLight} alt="" height="35" />
                </span>
              </Link>
            </div>
          </div>

          <ProfileMenu />
        </div>
      </header>
    </React.Fragment>
  );
};

export default withTranslation()(Header);
