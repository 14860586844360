import React from "react";

function IconInfo({ size = 24 }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="12" fill="currentColor" />
      <path
        d="M13.375 7.99994C14.4796 7.99994 15.375 7.10451 15.375 5.99994C15.375 4.89537 14.4796 3.99994 13.375 3.99994C12.2704 3.99994 11.375 4.89537 11.375 5.99994C11.375 7.10451 12.2704 7.99994 13.375 7.99994Z"
        fill="white"
      />
      <path
        d="M15.42 16.1151C15.3647 16.0779 15.3026 16.0522 15.2373 16.0393C15.1719 16.0265 15.1047 16.0269 15.0395 16.0404C14.9743 16.0539 14.9124 16.0803 14.8575 16.118C14.8027 16.1557 14.7559 16.204 14.72 16.2601C14.2366 16.9759 13.6445 17.6119 12.965 18.1451C12.8 18.2701 12.185 18.7451 11.925 18.6451C11.745 18.5901 11.85 18.2351 11.885 18.0851L12.15 17.3001C12.26 16.9801 14.175 11.3001 14.385 10.6501C14.695 9.7001 14.56 8.7651 13.145 8.9901C12.76 9.0301 8.85496 9.5351 8.78496 9.5401C8.7193 9.54437 8.65512 9.56152 8.59609 9.59059C8.53706 9.61967 8.48434 9.66008 8.44093 9.70952C8.39752 9.75897 8.36427 9.81649 8.34308 9.87878C8.3219 9.94108 8.31319 10.0069 8.31746 10.0726C8.32173 10.1383 8.33889 10.2024 8.36796 10.2615C8.39703 10.3205 8.43744 10.3732 8.48689 10.4166C8.53634 10.46 8.59385 10.4933 8.65615 10.5145C8.71844 10.5357 8.7843 10.5444 8.84996 10.5401C8.84996 10.5401 10.35 10.3451 10.515 10.3301C10.5995 10.3218 10.6846 10.3382 10.76 10.3773C10.8354 10.4163 10.8979 10.4763 10.94 10.5501C11.0306 10.8302 11.0181 11.1334 10.905 11.4051C10.775 11.9051 8.71996 17.6951 8.65496 18.0301C8.58525 18.3104 8.6046 18.6055 8.71034 18.8743C8.81608 19.1432 9.00294 19.3724 9.24496 19.5301C9.69922 19.8329 10.2401 19.9786 10.785 19.9451C11.3147 19.9386 11.8387 19.8335 12.33 19.6351C13.575 19.1351 14.875 17.8051 15.56 16.7601C15.6189 16.6539 15.6373 16.5299 15.6115 16.4112C15.5857 16.2925 15.5177 16.1873 15.42 16.1151Z"
        fill="white"
      />
    </svg>
  );
}

export default IconInfo;
