import * as React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import ProfileMenu from "components/ma/TopNavBar";
import TopNavBarLanding from "components/ma/TopNavBarLanding";
import { getAuthenticationStore } from "store/slice/authentication";

import logoDark from "assets/images/myachery/logo_myarhery.svg";
import logoLight from "assets/images/myachery/logo_myarhery.svg";
import logoLightSvg from "assets/images/myachery/logo_myarhery.svg";
import logo from "assets/images/myachery/logo_myarhery.svg";

const Header = () => {
  let { isLoggedIn } = useSelector(getAuthenticationStore);
  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <NavbarBrandBox className="navbar-brand-box">
              <Link to="/home" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logo} alt="" height="35" />
                </span>
                <span className="logo-lg">
                  <img src={logoDark} alt="" height="35" />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logoLightSvg} alt="" height="35" />
                </span>
                <span className="logo-lg">
                  <img src={logoLight} alt="" height="35" />
                </span>
              </Link>
            </NavbarBrandBox>
          </div>
          {!isLoggedIn ? <TopNavBarLanding /> : <ProfileMenu />}
        </div>
      </header>
    </React.Fragment>
  );
};

const NavbarBrandBox = styled.div`
  &.navbar-brand-box {
    @media (max-width: 380px) {
      display: block;
      padding: 0;
    }

    @media (max-width: 768px) {
      padding: 0;
    }
  }
`;

export default withTranslation()(Header);
