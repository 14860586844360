import * as React from "react";

function IconCertificate({ size = 24 }) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      style={{ enableBackground: "new 0 0 24 24" }}
      xmlSpace="preserve"
    >
      <style type="text/css">{`
        .st0{fill:#00A1FF;}
        .st1{fill:#FFFFFF;}
        .st2{fill:#FF005D;}
        .st3{fill:#59586E;}
        .st4{fill:#FFB43F;}
      `}</style>
      <g id="Layer_1"></g>
      <g id="Layer_2">
        <g>
          <path
            className="st0"
            d="M20.57,18.6H3.59c-1.9,0-3.43-1.54-3.43-3.43V4.34c0-1.9,1.54-3.43,3.43-3.43l16.98,0 c1.9,0,3.43,1.54,3.43,3.43v10.82C24,17.06,22.46,18.6,20.57,18.6z"
          />
          <g>
            <path
              className="st1"
              d="M22.02,6.41V8.8c0,1.15-0.93,2.08-2.08,2.08H4.22c-1.15,0-2.08-0.93-2.08-2.08V6.41c0,0,0-0.01,0-0.01 c0.03-1.29,1.15-1.04,1.15-1.04c1.77-0.1,1.7-1.04,1.77-1.95c0.02-0.29,0.2-0.46,0.43-0.56h13.19c0.23,0.1,0.41,0.27,0.43,0.56 c0.07,0.9,0,1.84,1.77,1.95c0,0,1.11-0.24,1.15,1.04C22.02,6.4,22.02,6.4,22.02,6.41z"
            />
            <path
              className="st1"
              d="M2.13,13.1v-2.39c0-1.15,0.93-2.08,2.08-2.08h15.72c1.15,0,2.08,0.93,2.08,2.08v2.39c0,0,0,0.01,0,0.01 c-0.03,1.29-1.15,1.04-1.15,1.04c-1.77,0.1-1.7,1.04-1.77,1.95c-0.02,0.29-0.2,0.46-0.43,0.56H5.48 c-0.23-0.1-0.41-0.27-0.43-0.56c-0.07-0.9,0-1.84-1.77-1.95c0,0-1.11,0.24-1.15-1.04C2.13,13.11,2.13,13.1,2.13,13.1z"
            />
            <polygon className="st1" points="22.02,7.54 22.02,12.03 19.38,9.52" />
            <polygon className="st1" points="2.13,7.54 2.13,12.03 4.78,9.52" />
          </g>
          <g>
            <rect x="8.87" y="16.7" className="st2" width="6.41" height="5.21" />
            <path
              className="st2"
              d="M8.87,22.25c-0.02,1.78,2.74,0.19,2.74,0.19l-2.74-1.3L8.87,22.25z"
            />
            <path
              className="st2"
              d="M15.28,22.25c0.02,1.78-2.74,0.19-2.74,0.19l2.74-1.3L15.28,22.25z"
            />
            <polygon className="st2" points="11.62,22.44 13.52,21.51 10.49,21.9" />
            <polygon className="st2" points="12.57,22.44 10.66,21.51 13.7,21.9" />
          </g>
          <path
            className="st3"
            d="M7.93,7.14h8.3c0.56,0,1.02,0.46,1.02,1.02v0.01c0,0.56-0.46,1.02-1.02,1.02h-8.3 c-0.56,0-1.02-0.46-1.02-1.02V8.16C6.91,7.6,7.36,7.14,7.93,7.14z"
          />
          <circle className="st4" cx="12.08" cy="14.3" r="3.95" />
        </g>
      </g>
    </svg>
  );
}

export default IconCertificate;
